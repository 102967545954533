
<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
// import Switches from "vue-switches";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    // Switches
  },
  data() {
    return {
      Banners: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchBanners: null,
      BannersSearchMode: false,
      searchModel: "",
      state: state
    };
  },
  methods: {
     switchStatus(app) {

      // this.status = !this.status
      app.is_active = !app.is_active;
      this.http
        .put(
            "order-granted-commission", app?.id, { is_active: app.is_active })
        .then((res) => {
          if (res.status) {
            this.get(this.page);
          }
        });
    },

    addBanners() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addBanners",
        inputs: [
          {
            model: "image",
            type: "file",
            label: "image"
          }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.do(
                "order-granted-commission", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    editBanners(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "vendor_id",
            type: "text",
            label: "vendor_id",
            value: app.vendor_id
          },
          {
            model: "image",
            type: "file",
            label: "image",
            value: app.image
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.put(
                "order-granted-commission", app.id, obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    updateBanners(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "image",
            type: "text",
            label: "image"
          }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.emp_id = app.id;
              this.http.do(
                "order-granted-commission", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    search() {
      this.BannersSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post(
            "order-granted-commission/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: ""
        })
        .then(res => {
          this.Banners = res.data;
          console.log("######## files", this.Banners);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.BannersSearchMode = false;
      this.get(this.page);
    },

    getBanners() {
      this.http.get(
        "order-granted-commission").then(res => {
        this.Banners = res.data;
      });
    },
    deleteBanners(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          console.log("###");
          this.http.delete(
            "order-granted-commission", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post(
            "order-granted-commission/paginate", {
          limit: this.limit,
          page: page
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Banners = res.data;
        });
    }
  },
  created() {
    this.get(1);
    // this.getBanners();
  }
}
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.commission.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-3">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t('banners.id') }}</th>
          <!-- <th scope='col'>{{ $t('banners.vendor_id') }} -->
          <!-- </th> -->
          <th scope="col">{{ $t('banners.name') }}</th>
          <th scope="col">{{ $t('banners.commission') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app,index) in Banners" :key="app" class="text-center">
          <td>{{ index+1}}</td>
          <td>{{ $i18n.locale == "ar" ? app?.brand_name_ar : app?.brand_name}}</td>
          <td>{{ app.commission_value}}</td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">
            {{
            p
            }}
          </a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




